.icon-bar{
  display: flex;
  flex-direction: column;
  border-right: thin solid lightgray;
}
.iconbar-icon{
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;
}

.dropdown-content{
  position: absolute;
  background-color: white;
}

.dropdown-content-input{
  position: absolute;
  background-color: white;
  border: none;
}

.ant-menu-vertical.ant-menu-sub{
min-width: 200px !important;
}