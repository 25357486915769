.headerMain {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap:nowrap;
    padding: 0 20px;
    border-bottom: thin solid lightgray;
}
.headerCol {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    width: 5px;
    margin-left: 30px;
    
}
.titleBarButtons{
    width: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 480px) {
    .headerCol{
        align-items: center;
        justify-content: center;
    }    
    .titleBarButtons{
        width: fit-content;
    }
}


