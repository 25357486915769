/* .SavingSaved{
    display: flex;
    margin-top: 15px;
    margin-left: 10px;
  font-Size:12px
  } */
  .SavingSaved {
    display: flex;
    width: auto;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}