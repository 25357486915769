.chats{
    position: relative;
    margin-bottom: 4px;
    width: 270px;
    height: 300px;
    border: 2px solid #526bb1;
    background-color: white;
    padding: 10px;
    overflow-y: scroll;
    z-index: 3000;
    border-radius: 0px 0px 14px 14px;
    overflow-x:hidden;
}
.chats::-webkit-scrollbar{
    width: 10px;
    background-color: #fff;
}
.chats::-webkit-scrollbar-thumb{
    background-color: #c7c7c7;
    border-radius: 10px;
}
.message-input{
    width: 100%;
    /* position: absolute; */
    bottom: 0px;
    background-color: white;
    border: 2px solid #526bb1;
    z-index: -10;  
    border-radius: 14px 14px 14px 14px;

}
.message-input > input{
    width: 90%;
    padding: 5px;
    outline: none;
    border: none;
    z-index: 2000;
    border-radius: 14px 14px 14px 14px;
    background-color: #fff;
}
.message-input > input:focus{
    outline: none;
}
.send-icon{
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: 2px;
}
.message-container {
    word-wrap: break-word; /* Allow word wrapping within the container */
  }

  /* * Add CSS classes for slide animations */

.message-container.slide-in-left {
    animation: slideInLeft 0.35s ease-in-out;
  }
  
  .message-container.slide-in-right {
    animation: slideInRight 0.45s ease-in-out;
  }
  
  @keyframes slideInLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }