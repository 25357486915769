@media print {
    @page {
        size: landscape;
        margin: 0;
    }

    body {
        margin: 0;
        padding: 0;
    }

    #my-canvas-board {
        position: relative;
        top: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        page-break-inside: avoid !important;
        page-break-before: avoid !important;
        page-break-after: avoid !important;
        box-shadow: none;
    }
   
}