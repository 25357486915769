.title-container{
    margin-bottom: 5px;
}

.title{
    height: 20px;
    width: fit-content;
    border: none;
    letter-spacing: 2px;
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-top: 15px;
}
.title:focus{
    outline:none;
}


@media screen and (max-width: 480px){
    .title{
        margin: 0;
    }
}