.chat-box-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 19000;
  overflow: hidden;
}

.chat-togglebar {
  cursor: pointer;
  width: 270px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  /* border-radius: 4px 4px 0px 0px; */
  background-color:#526bb1;
  color: white;
  z-index: 35500;
  border-radius: 14px 14px 0px 0px

}
.chat-toggle-icon {
  /* position: fixed; */
  /* left: 90%; */
  /* bottom: 1%; */
  /* z-index: 99999; */
  width: 90px;
  /* cursor: pointer; */
}
.message-counter {
  background-color: red; /* You can customize the background color */
  color: white;
  border-radius: 50%;
  padding: 2px 7px;
  position: absolute;
  top:20px;
  right: 25px;
  /* left: 93%; */
  /* bottom: 6%; */
  z-index: 100000;
  pointer-events: none;
  font-size: 10px;
  height: fit-content;
}
.chat-toggle-icon-container {
  display: flex;
  position: fixed;
  right: 60px;
  bottom: 50px;
  z-index: 99999;
  /* width: 50px; */
  cursor: pointer;
}
@media screen and (max-width:768px)  {
/* @media screen and (max-width: 767px) { */

.message-counter {
  
  /* position: absolute;
  left: 98%;
  bottom: 17.5%; */

}
.chat-toggle-icon-container {
  bottom: 100px;
  right: 10px;


}
}
