.titleWrapper{
    width: max-content;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: "#00";
    box-shadow: 'rgb(101 92 92 / 50%) 0px 3px 8px';
    border-radius: 5px;
    padding: 20;
    position: relative;
    z-index:10 ;
}
.offlineStatus {
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    top: 15px;
    width: max-content;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #303030;
    box-shadow: rgb(255 0 0 / 50%) 0px 3px 8px;
    border-radius: 5px;
    padding: 14px;
    position: absolute;
    z-index: 10;
    white-space: nowrap;
    border-bottom: 2px solid red;
  }
  @media screen and (max-width: 768px) {
    .offlineStatus {
    top: 75px;

      /* font-size: 12px; */
    }
  }
  .offlineStatus h5{
    color: #fff;
  }
  
  