body {
  background-color: #F0F0F0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .canvas-container{
  width: 100vw;
  height: 99vh;
  overflow: hidden;
  position: relative;
} */
/* .canvas-btn-container{
  cursor: grab;
  width: 130px;
  position: fixed;
  bottom: 50px;
  left: 50px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
} */
