
.navigationmain {
    display: flex;
    font-size: 15px;
    font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    color: #202124;
    align-items: baseline;
}


@media screen and (max-width: 480px){
    .navigationmain{
        display: none;
        
    }
}